import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

type LayoutProps = {
  elements: string[]
}

const Features: React.FC<LayoutProps> = (props) => (

  <section className="text-gray-600 body-font">
    <div className="container px-5 py-12 mx-auto">
      <div className="flex flex-wrap">
        {props.elements.indexOf('why') !== -1 ? <div className={`${props.elements.length > 2 ? 'lg:w-1/3' : 'lg:w-1/2'} md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60`}>
          <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Why choose hypnotherapy</h2>
          <p className="leading-relaxed text-base mb-4">Safe, practical, short term and effective therapy.</p>
          <Link to="/blog/why-choose-hypnotherapy" className="text-indigo-500 inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div> : ''}
        {props.elements.indexOf('what') !== -1 ? <div className={`${props.elements.length > 2 ? 'lg:w-1/3' : 'lg:w-1/2'} md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60`}>
          <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">What is hypnotherapy</h2>
          <p className="leading-relaxed text-base mb-4">A natural state of focussed attention many of us experience every day.</p>
          <Link to="/blog/what-is-hypnotherapy" className="text-indigo-500 inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div> : ''}
        {props.elements.indexOf('zoom') !== -1 ? <div className={`${props.elements.length > 2 ? 'lg:w-1/3' : 'lg:w-1/2'} md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60`}>
          <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Hypnotherapy via zoom</h2>
          <p className="leading-relaxed text-base mb-4">Maintaining safe and effective services for clients.</p>
          <Link to="/blog/hypnotherapy-via-zoom" className="text-indigo-500 inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div> : ''}
      </div>
      {/*<button className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>*/}
    </div>
  </section>
)

export default Features
