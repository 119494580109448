import * as React from "react"

import { useStaticQuery, PageProps, Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Features from "../../components/features"

type DataProps = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string;
    }
  }
}

const BlogPage: React.FC<PageProps<DataProps>> = (props) => (
  <Layout {...props}>
    <Seo title="Blog - Hypnotherapy via zoom" />

        <article className="prose lg:prose-xl py-24 mx-auto">
          <h1 className="">Hypnotherapy via zoom</h1>

          <p className="lead">As with most, if not all professions,  hypnotherapists  found new ways to overcome the restrictions imposed since the start of the pandemic, to maintain safe and effective services for clients.</p>
          <p className="">It quickly became apparent that using video link apps to reach clients was very successful. With only a few minor  limitations compared to face to face  hypnotherapy sessions. In fact  there are some advantages  to using apps such as Zoom to undertake  hypnotherapy sessions remotely -  for example no travel issues. This can be of benefit where the client  has mobility issues, time constraints, or is travelling frequently.</p>
          <blockquote><p className="">It is important that the client has access to a quiet, comfortable  area where there will be limited distractions during the period of the video link hypnotherapy session.</p></blockquote>
          <p className="">All that is required for hypnotherapy session via video link is a device such as laptop, tablet or mobile phone, with reasonable  camera and sound facilities  and a reliable  wi fi connection.</p>
          <p className="">The therapist would send an email with a  link to join  the session remotely at the agreed appointment time. Client confidentiality and privacy will be maintained.</p>
          <p className="">There are a few additional  safety measures the hypnotherapists will take,  for example ensuring  they have a mobile phone number for the client as  an alternative  option to maintain  contact  should there be any disruption in  wifi signal. Also having the details of an alternative contact for the client should any difficulties arise during the session.</p>
          <p className="">It is important that the client has access to a quiet, comfortable  area where there will be limited distractions during the period of the video link hypnotherapy session.</p>
          <p className="">Now that face to face contact with Covid -19 precautions in place, is  a safe possibility again,  clients have the option of having hypnotherapy either way depending on their preference.</p>
        </article>
        {/*<h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">ROOF PARTY POLAROID</h2>*/}

    <Features elements={['why', 'what']} />
  </Layout>
)

export default BlogPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
      siteMetadata {
        title
      }
    }
  }
`
